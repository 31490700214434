import React from "react";
import { Card, Row, Col, Container } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa'

import Page from "../../../components/Page";
import BannerParallax from "../../../components/BannerParallax";
import SectionFAQ from "../parts/SectionFAQ";
import SectionTestimonial from "../parts/SectionTestimonial";

import imgBanner from "../../../images/banner/banner-7-music-world-wide.jpg";
import imgTestiChairis from "../../../images/testi/img-test-chairis.jpg";
import imgTestiIzqa from "../../../images/testi/img-test-izqa.jpg";
import imgTestiIndra from "../../../images/testi/img-test-indra.jpg";
import img1 from "../../../images/online-program/mr-online-program-music-world-wide-1.jpg";
import img2 from "../../../images/online-program/mr-online-program-music-world-wide-2.jpg";
import img3 from "../../../images/online-program/mr-online-program-music-world-wide-3.jpg";
import img4 from "../../../images/online-program/mr-online-program-music-world-wide-4.jpg";
import img5 from "../../../images/online-program/mr-online-program-music-world-wide-5.jpg";
import img6 from "../../../images/online-program/mr-online-program-music-world-wide-6.jpg";

export default () => {
  // const pageTitle = "MusicWorldWide"
  const pageTitle = "Online Program"
  const pageDescription = "Music World Wide - customized, one-on-one, live, online music lesson"
  const DATA_FAQ = [
    {
      id: "0",
      question: "What do I need to prepare for the lesson?",
      answer: "Your instrument, and ideally 2 different gadgets with ZOOM installed (one for you to see the instructor and another one showing your hand position on the instrument). However, if you only have 1 gadget, that is okay, we will work with what you have. If you choose vocal  or drum lesson, you will need 2 different gadgets. One for ZOOM, and the other to play your backing track when necessary."
    },
    {
      id: "1",
      question: "For Music Theory and Song Writing, what do I need to prepare?",
      answer: "A piano, a keyboard, or a guitar."
    },
    {
      id: "2",
      question: "What if I reach my goal sooner?",
      answer: "If you reach your goal in the middle of your lesson package, you may set a new goal and let your instructor know. The instructor will whip up another lesson plan for your 2nd goal."
    },
    {
      id: "3",
      question: "What if I don’t reach my goal?",
      answer: "If you are busy and do not have much time practicing, there is a possibility that you won’t reach your goal in 3, 10, or 20 lessons. However, your instructor will have equipped you with materials with which you can practice on your own after the program ends. That way, whenever you have some practicing time in the future, you simply need to follow the step-by-step materials."
    },
  ];
  const DATA_TESTIMONIAL = [
    {
      name: "Chairis Yoga",
      desc: "Enterpreneur & Aspiring Vocal Coach",
      testi: "The instructor really understood me and was able to cater to polishing my musical theatre singing techniques, correcting my vibrato, and teaching me details on how to convey stories through my voice. I feel grateful and blessed. My next goal is to be able to coach those who share the same interest.",
      img: imgTestiChairis
    },
    {
      name: "Izqa Rakhmadani",
      desc: "High School Student, Vocalist for Youth Band",
      testi: "I love the fact that the instructors are very patient and supportive in shaping me to be a better musician. They are really fun and easy to talk to, which make my lessons something I look forward to every week!",
      img: imgTestiIzqa
    },
    {
      name: "Indra Suryati",
      desc: "Housewife, Former 80s Jazz Artist",
      testi: "My instructor is a great combination of teaching in a casual, yet creative manner. She guides me through clear steps to tackle new repertoire (out of my comfort zone) with just the right approach, which I absolutely enjoy. ",
      img: imgTestiIndra
    },
  ]
  const DATA_IMG = [
    { id: 5, src: img5, title: "", desc: "" },
    { id: 2, src: img2, title: "", desc: "" },
    { id: 4, src: img4, title: "", desc: "" },
    { id: 3, src: img3, title: "", desc: "" },
    { id: 1, src: img1, title: "", desc: "" },
    { id: 6, src: img6, title: "", desc: "" },
  ];

  return (
    <Page title={pageTitle} description={pageDescription} className="page-music-world-wide">
      <BannerParallax title={pageTitle} background={imgBanner} />

      {/* Title */}
      <section className="section_padding">
        <div className="container">
          {/* page title */}
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="section_title text-center">
                <h1>MusicWorldWide</h1>
                <span>customized, one-on-one, live, online music lesson</span>
              </div>
            </div>
          </div>

          {/* video */}
          <div className="row mb-4">
            <div className="col-md-10 mx-auto">
              <div className="mb-2">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe title="Music Republicain Online Lesson" className="embed-responsive-item" src="https://www.youtube.com/embed/cv4V45jm754" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
              <section>
              </section>
            </div>
          </div>

          {/* description */}
          <div className="row mb-4">
            <div className="col-md-10 mx-auto">
              <p className="lead"><span className="font-weight-bold">Learn music one-on-one, live &amp; online, with a customized lesson plan tailored specifically to help you reach your goals.</span> Whether you’d like to play a song to impress your loved ones, write a song for fun to post at your social media, or simply fill your days with the joy of music learning, we are here for you!</p>
              <p className="lead">A highly-skilled instructor will be assigned to work side-by-side with you. Your personal instructor will first create a personalized lesson plan, before teaching you the all the fundamental music concepts &amp; techniques to enable you to reach your goal in just <span className="font-weight-bold">3, 10, or 20 lessons @ 30 minutes each</span>.</p>
              <p className="lead">This program is most suited for Beginner &amp; Intermediate levels.</p>
              <ul className="unordered-list lead">
                <span className="font-weight-bold">Choose one of the following instruments/topics:</span>
                <li>Vocal</li>
                <li>Piano</li>
                <li>Guitar (acoustic/electric)</li>
                <li>Bass (doublebass/electric)</li>
                <li>Drums</li>
                <li>Violin</li>
                <li>Flute</li>
                <li>Saxophone</li>
                <li>Music Theory</li>
                <li>Song Writing</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* PRICING */}
      <section className="section_padding section_bg">
        <Container>
          <Row>
            <Col>
              {/* <!-- Section heading --> */}
              <h2 className="h1-responsive font-weight-bold mb-5 text-center">PRICING</h2>
            </Col>
          </Row>

          {/* price list */}
          <Row>
            <div className="card-deck col-md-10 mx-auto">
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  <h4 className="my-0 font-weight-normal">Package of 3</h4>
                </Card.Header>
                <Card.Body>
                  <h1 className="card-title pricing-card-title text-center">USD 167 <br /> <small>(IDR 2,400,000)</small></h1>
                  <ul className="unordered-list lead mt-3 mb-4">
                    <li>3 lessons</li>
                    <li>maximum 4 weeks</li>
                  </ul>
                </Card.Body>
                <Card.Footer>
                  <a href="https://forms.gle/YccMav2k2BPhoWam9" target="_blank" rel="noreferrer" className="btn btn-lg btn-block btn-primary btn_1">Get Started</a>
                </Card.Footer>
              </Card>
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-center">
                  <h4 className="my-0 font-weight-normal">Package of 10</h4>
                </Card.Header>
                <Card.Body>
                  <h1 className="card-title pricing-card-title text-center">USD 407 <br /> <small>(IDR 5,900,000)</small></h1>
                  <ul className="unordered-list lead mt-3 mb-4">
                    <li>10 lessons</li>
                    <li>maximum 3 months</li>
                  </ul>
                </Card.Body>
                <Card.Footer>
                  <a href="https://forms.gle/YccMav2k2BPhoWam9" target="_blank" rel="noreferrer" className="btn btn-lg btn-block btn-primary btn_1">Get Started</a>
                </Card.Footer>
              </Card>
              <Card className="mb-4 shadow-lg bg-info border border-info">
                <div className="d-none d-md-block">
                  <div className="best-deal h4">
                    <FaStar className="mr-1 text-warning" />
                    <div className="text-info">Best Deal!</div>
                  </div>
                </div>
                <Card.Header className="text-center">
                  <h4 className="my-0 font-weight-normal text-light">
                    Package of 20
                  </h4>
                </Card.Header>
                <Card.Body>
                  <h1 className="card-title pricing-card-title text-center text-light">USD 527 <br /> <small>(IDR 7,700,000)</small></h1>
                  <ul className="unordered-list lead mt-3 mb-4 text-light">
                    <li>20 lessons</li>
                    <li>maximum 6 months</li>
                  </ul>
                </Card.Body>
                <Card.Footer>
                  <a href="https://forms.gle/YccMav2k2BPhoWam9" target="_blank" rel="noreferrer" className="btn btn-lg btn-block btn-primary btn_1 bg-light text-dark">Get Started</a>
                </Card.Footer>
              </Card>
            </div>
          </Row>

          <Row>
            <Col className="col-md-10 mx-auto">
              {/* <!-- Section description --> */}
              <div className="px-md-3 pt-3">
                <h4 className="text-uppercase">Lesson schedule</h4>
                <p className="dark-grey-text w-responsive">
                  <ul className="unordered-list">
                    <li>Once you complete payment, we will contact you within 48 hours. You will then discuss the lesson schedule with the instructor.</li>
                    <li>We strongly encourage you to set a specific lesson day and time, and stick to the same schedule every week. Pace yourself once a week for lesson, to allow adequate practicing time in between.</li>
                    <li>However, if you are keen on taking your lesson twice or thrice weekly, you may do so. Simply let your instructor know to arrange the lesson schedule accordingly. </li>
                  </ul>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* TESTIMONIAL */}
      <section className="section_padding">
        <SectionTestimonial data={DATA_TESTIMONIAL} />
      </section>

      {/* IMAGES */}
      <section className="section_padding section_bg">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="card-columns">
                {DATA_IMG.map(image => (
                  <div key={image.id} className="card">
                    <img
                      className="w-100"
                      key={image.id}
                      src={image.src}
                      title={image.id}
                      alt={image.description}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Row>
        </Container>
      </section>

      {/* FAQ */}
      <section className="section_padding padding_bottom">
        <SectionFAQ data={DATA_FAQ} />
      </section>
    </Page >
  )
}
